@import "variables";

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 100vw;
}

.text-xl {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 2.1vw; // 65
}

.text-l {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 1.8vw; // 54
}

.text-m {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 1.4vw; //41
}

.text-s {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 1.1vw; //32
}

.text-xs {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 0.8vw; //25
}

.text-sub {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 3.1vw; // 96
}

.text-tooltip {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 0.6vw; //19
}

.text-coming-soon {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 0.9vw; //28
}

.card-title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 1.8vw;
}

.text-xxl-mob {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0;

  font-size: 6.5vw; //86
}

.text-xl-mob {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0;

  font-size: 5.75vw; //76
}

.text-l-mob {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0;

  font-size: 4.1vw; //54
}

.text-m-mob {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 3.2vw; //42
}

.text-s-mob {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 2.75vw; //36
}

.text-xs-mob {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 2.25vw; //30
}

.text-sub-mob-1 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 10.5vw; // 138
}

.text-sub-mob-2 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 7.15vw; // 94
}

.text-play-demo-mob {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  line-height: normal;

  font-size: 7vw;
}

.font-w-900 {
  font-weight: 900;
  font-style: italic;
}

.font-w-700 {
  font-weight: 700;
}

.font-w-400 {
  font-weight: 400;
}

.white-color {
  color: $white;
}

.dark-blue-color {
  color: $dark-blue;
}

.turquoise-color {
  color: $turquoise;
}

.coming-soon-color {
  color: $coming-soon;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.pointer-events-none {
  pointer-events: none;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-default {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-start {
  text-align: start;
}